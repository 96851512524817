import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography, Button } from "antd";
import Heading from "../../components-v2/Base/HeadingBuilder";
import WhiteGloveBG from "./WhiteGloveBG";
// import Image from '../ImageQuerys/WhiteGloveImages';
import useWindowSize from "../../hooks/use-window-size";
import CtaPrimary from "../Buttons/primary-cta";
import CtaSecondary from "../Buttons/secondary-cta";
import arrow from "../../images/svg/long-right-arrow.svg";
import WhiteGloveStyles from "./WhiteGloveStyles";
import phone from "../../images/svg/phone.svg";
import playIcon from "../../images/svg/play.svg";
import chevron from "../../images/svg/chevron_left.svg";

const { Title } = Typography;

const WhiteGlove = (props) => {
  const { sectionData, whiteGloveBg, whiteGloveBgMobile, noTime } = props;
  const [imageToLoad, setImageToLoad] = useState(null);
  const {
    mainTitle,
    title,
    content,
    contentList,
    ctaInfoPrimary,
    ctaInfoSecondary,
    ctaPhone,
    ctaEmail,
  } = sectionData;

  const size = useWindowSize();

  useEffect(() => {
    const { width } = size;
    const isMobileOnly = width <= 576;

    const images = () => {
      if (isMobileOnly) {
        if (whiteGloveBgMobile) {
          return whiteGloveBgMobile;
        }
        return whiteGloveBg;
      }
      return whiteGloveBg;
    };
    setImageToLoad(images);
  }, [size]);
  return (
    <section className="white-glove">
      <WhiteGloveBG className="white-glove__visual" imageName={imageToLoad}>
        <span className="gradient-cover" />
        <Heading level={2} injectionType={2} className="white-glove-title">
          {mainTitle}
          <span className="blue_dot">.</span>{" "}
        </Heading>
        {!noTime && (
          <p>
            24 <span>/</span> 7 <span>/</span> 365
          </p>
        )}
      </WhiteGloveBG>
      <Row className="white-glove__content">
        <Col lg={12} md={24}>
          <Title level={2}>
            {title}
            <span className="blue_dot">.</span>{" "}
          </Title>
          <Row justify="start">
            {ctaInfoPrimary && (
              <CtaPrimary
                ctaTitle={ctaInfoPrimary.ctaTitle}
                target={ctaInfoPrimary.ctaTarget}
                className="mobile-hidden"
              />
            )}
            {ctaInfoSecondary && (
              <CtaSecondary
                ctaTitle={ctaInfoSecondary.ctaTitle}
                target={ctaInfoSecondary.ctaTarget}
                className="mobile-hidden inline"
              />
            )}
            {ctaEmail && (
              <div className="hidden lg:block custom-force-desktop">
                <Button
                  className="cta-primary mt-4 "
                  type="primary"
                  href={`mailto:${ctaEmail.ctaMail}`}
                >
                  <span className="text-[18px] pt-2 ml-2">
                    {ctaEmail.ctaText}
                  </span>
                  <img
                    src={chevron}
                    alt="chevron icon"
                    className="translate-y-[2px]"
                    style={{ margin: "4px 5px 2px 10px" }}
                  />
                </Button>
              </div>
            )}
            {ctaPhone && (
              <a
                className="bg-black-800 px-[16px] py-[8px] mt-3.5 rounded-full hidden max-w-[200px] w-full lg:block custom-force-desktop"
                href={`tel:${ctaPhone.phoneNumber}`}
              >
                <img src={phone} alt="phone icon" />
                <span className="text-[18px] ml-2">{ctaPhone.phoneText}</span>
              </a>
            )}
          </Row>
        </Col>
        <Col lg={12} md={24}>
          <p>{content}</p>
          {contentList && (
            <ul style={{ paddingBottom: 20 }}>
              {contentList.map((item) => (
                <li className="flex" key={item.toString()}>
                  <img src={arrow} alt="" />
                  <span className="inline-block max-w-[90%]">{item}</span>
                </li>
              ))}
            </ul>
          )}
          <Row justify="center">
            {ctaInfoPrimary && (
              <CtaPrimary
                ctaTitle={ctaInfoPrimary.ctaTitle}
                target={ctaInfoPrimary.ctaTarget}
                className="desktop-hidden no-right"
              />
            )}
            {ctaEmail && (
              <div className="block lg:hidden custom-force-mobile">
                <Button
                  className="cta-primary mt-4"
                  type="primary"
                  href={`mailto:${ctaEmail.ctaMail}`}
                >
                  <span className="text-[18px] pt-2 ml-2">
                    {ctaEmail.ctaText}
                  </span>
                  <img
                    src={chevron}
                    alt="chevron icon"
                    className="translate-y-[2px]"
                    style={{ margin: "4px 5px 2px 10px" }}
                  />
                </Button>
              </div>
            )}
            {ctaPhone && (
              <a
                className="bg-black-800 px-[16px] py-[8px] rounded-full block translate-x-[-15px] max-w-[200px] w-full lg:hidden mt-4 custom-force-mobile"
                href={`tel:${ctaPhone.phoneNumber}`}
              >
                <img src={phone} alt="phone icon" />
                <span className="text-[18px] ml-2">{ctaPhone.phoneText}</span>
              </a>
            )}
          </Row>
        </Col>
      </Row>

      <WhiteGloveStyles />
    </section>
  );
};

WhiteGlove.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  whiteGloveBg: PropTypes.string,
  whiteGloveBgMobile: PropTypes.string,
  noTime: PropTypes.bool,
};
WhiteGlove.defaultProps = {
  sectionData: "",
  whiteGloveBg: "",
  whiteGloveBgMobile: null,
  noTime: false,
};

export default WhiteGlove;
