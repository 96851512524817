import React from "react";
import { viewports, colors } from "../../style-vars";

const { mdOrSmaller, lgOrBigger } = viewports;
const { primary } = colors;

const WhiteGloveStyles = () => (
  <style jsx global>{`
    .white-glove {
      position: relative;
      margin: 40px 100px;
    }
    .white-glove-title {
      max-width: calc(100% - 32px);
      display: table;
      margin: 0 auto;
    }
    @media ${mdOrSmaller} {
      .white-glove {
        margin: 40px 32px 40px 32px;
      }
    }
    .white-glove__visual {
      position: relative;
      border-radius: 28px;
      max-height: 520px;
      min-height: 420px;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 auto;
      overflow: hidden;
    }
    .white-glove__visual h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #f3f4f6 !important;
      width: 100%;
      text-align: center;
    }
    .white-glove__visual p {
      font-size: 32px;
      font-weight: 800;
      color: #f3f4f6;
      position: absolute;
      bottom: 32px;
      left: 50%;
      width: 100%;
      text-align: center;
      transform: translate(-50%, 32px);
    }
    .white-glove__visual p span {
      color: ${primary};
      line-height: 2;
      letter-spacing: 6px;
    }
    .white-glove__content {
      margin-top: 70px;
    }
    @media ${mdOrSmaller} {
      .white-glove__content {
        margin-top: 32px;
      }
      .white-glove__content h2 {
        margin-right: 0 !important;
        white-space: pre-line !important;
      }
    }
    .white-glove__content h2 {
      font-weight: 800 !important;
      margin-right: 30%;
      margin-bottom: 32px !important;
      line-height: 1.5 !important;
      white-space: pre-line !important;
    }
    .white-glove__content p {
      color: #748093;
      font-size: 16px;
      line-height: 2;
    }
    .white-glove__content ul {
      padding: 0;
      list-style: none;
    }
    .white-glove__content ul li {
      margin-bottom: 16px;
    }
    .white-glove__content ul li span {
      font-weight: 800;
    }
    .white-glove__content ul li img {
      margin-right: 16px;
    }
    .white-glove .inline {
      display: inline-block !important;
    }
    .white-glove .cta-secondary {
      padding-left: 24px !important;
    }
    @media ${mdOrSmaller} {
      .white-glove .mobile-hidden {
        display: none !important;
      }
    }
    @media ${lgOrBigger} {
      .white-glove .desktop-hidden {
        display: none !important;
      }
    }

    @media screen and (max-width: 1023px) {
      .custom-force-desktop {
        display: block !important;
      }
      .custom-force-mobile {
        display: none !important;
      }
    }
    @media screen and (max-width: 991px) {
      .custom-force-desktop {
        display: none !important;
      }
      .custom-force-mobile {
        display: block !important;
      }
    }
  `}</style>
);

export default WhiteGloveStyles;
